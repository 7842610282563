import React,{useState,useEffect} from 'react'
import {connect} from 'react-redux'
import logo from "assets/images/logoYnovWhite.png";
import logo2 from "assets/images/icone.png";
import { IconButton } from '@material-ui/core';
import {Link, Switch, Route, Redirect } from "react-router-dom";
import {courtage,bankass,com,settings,producteur,branche,technique,shared,bni} from "routes";
import FooterNew from 'components/Footer/FooterNew';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks";
import {selectPartenaire} from 'redux/actions/settingsAction'



const MasterPartenaire = (props) => {
  let currentRoute
  const {ui: { layoutRoot },user:{credentials}} =props

  if (layoutRoot === 'bankass') {currentRoute = (credentials.codepartenaire ==='092') ? bni.concat(shared): bankass.concat(shared) }
  if (layoutRoot === 'courtage') {currentRoute=courtage.concat(shared)}
  if (layoutRoot === 'com') {currentRoute=com.concat(shared)}
  if (layoutRoot === 'branche') {currentRoute=branche}
  if (layoutRoot === 'technique') {currentRoute=technique}
  if (layoutRoot === 'settings') {currentRoute=settings}
  if (layoutRoot === 'producteur') {currentRoute =producteur}
  if (layoutRoot === 'medecin') {currentRoute=[]}

  let newRoute = currentRoute&&currentRoute.filter(item=>(item.partenaire.includes(credentials.codepartenaire) ||item.partenaire.includes('ALL')))
    let defaultPath = newRoute?newRoute.filter(item=>item.default===true)[0]?.path :'/tableau-de-bord'

    const [open,setOpen] = useState(true)
    const [partenaire,setPartenaire] =useState(null)
    const handlePartenaire =()=>{
      if(credentials){
        selectPartenaire(credentials?.codepartenaire).then(res=>{
          if(res){setPartenaire(res)}
        })
      }
    }
    useEffect(()=>{
      handlePartenaire()
    },[])
   
  return (
    <div className='bg-green-900 px-2 min-h-[100vh] flex flex-col'>

          <div className='flex p-2 flex-row items-center  h-[80px]'>
              <div className={open ? "w-72" : "w-20 flex items-center justify-center p-1"}>
                {open ? <img src={logo} className="cursor-pointer ml-4 w-32"/> : <img src={logo2} className="cursor-pointer w-10 h-10 p-1 rounded-full bg-white"/>}
              </div>
              <div className="hidden md:flex w-full items-center justify-between">
                <IconButton className="left-0" onClick={()=>setOpen(!open)}>
                    <FormatAlignLeftIcon className={`text-white ${!open && "transform rotate-180"}`}/>
                </IconButton>
                  <div className="flex gap-1">
                      <AdminNavbarLinks color='white' />
                  </div>
              </div>
          </div>

        <div className={`flex`}>
            <div className={`${open ? "w-72" : "w-20"} hidden md:flex  flex-col h-[100vh] gap-2`}>
              <div className={`flex flex-col bg-[#3d8a415a] rounded-2xl ${!open&&"hidden"}`}>
                {partenaire ? <img className='flex object-cover rounded-lg' src={require(`assets/images/${(partenaire && partenaire.code!=='')?partenaire.code+'.jpg':'logo_yako.jpg'}`)} /> : (<img className='flex object-cover rounded-lg' src={require(`assets/images/logo_yako.jpg`)} />)
                }
              </div>
              <div className="hidden md:flex  flex-col gap-4 my-2 p-3 rounded-2xl bg-[#3d8a415a] outline-1 h-full">
                {newRoute.map((r,idx)=>(r.isRub&&<Link key={idx} to={`/${layoutRoot}${r['path']}`} className={`h-9 w-full group relative gap-4 pl-4 ${open&&"border"} outline-1 bg-transparent border-slate-50 rounded-full flex items-center justify-center`}>
                      <r.icon className={`${open ? "absolute h-9 w-9" : "relative w-11 h-11"} group-hover:bg-secondary-main group-hover:text-white group-hover:transform scale-50  left-[-10px] bg-slate-100 rounded-full p-1 text-slate-400 origin-left`}/>
                      <span className={`my-2 text-slate-100  ${!open && "hidden"}`}>{r['name']}</span>
                 
                  </Link>))}
              </div>
            </div>
            
            <main className='bg-white rounded-xl mt-2 mx-3 flex-1'>
                 {newRoute&&<Switch>
                    {newRoute.map((prop, key) =>  (<Route path={prop.layout=='()' ? (`/${layoutRoot+prop.path}`) : (prop.layout + prop.path)} component={prop.component} key={key}/>))}
                    <Redirect from={`/${layoutRoot}`} to={`${layoutRoot+defaultPath}`} />
                  </Switch>}
            </main>
        </div>

        <div className='flex justify-center row-span-1 items-center'><FooterNew /></div>   
    </div>
  )
}
const mapStateToProps = (state) => ({
    ui: state.UI,
    user: state.user
  });
  
  const mapActionsToProps = {}
export default connect(mapStateToProps, mapActionsToProps)(MasterPartenaire)